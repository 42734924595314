
// Vue reactivity
import { computed, ref, watch, } from "vue";

// icons
import { search, searchOutline, notificationsOutline, bookmarksOutline, giftOutline, filter, checkmark, closeCircle, } from 'ionicons/icons';

// components
import { IonPage, IonHeader, IonContent, IonGrid, IonRow, IonCol, IonToolbar, IonTitle,
        IonSearchbar, IonButtons, IonButton, IonBackButton, IonSpinner,
        IonSegment, IonSegmentButton, IonLabel, IonList, IonItem, IonIcon,
        IonThumbnail, IonImg, IonChip, IonRefresher, IonRefresherContent,
        IonInfiniteScroll, IonInfiniteScrollContent, IonSelect, IonSelectOption, IonPopover,
        onIonViewDidEnter, } from "@ionic/vue";
import UseCouponModal from "@/components/order/UseCouponModal.vue";
import CartButton from "@/components/CartButton.vue";
import LoadingSkeleton from "@/components/LoadingSkeleton.vue";
import GetGiftToolbar from '@/components/headers/GetGiftToolbar.vue';
import LogoImg from "@/components/LogoImg.vue";

// composables
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { utils } from '@/composables/utils';

// services
import ProductService from '@/services/ProductService';

export default {
  name: "ProductListPage",
  components: {
    IonPage, IonHeader, IonContent, IonGrid, IonRow, IonCol, IonToolbar, IonTitle,
    IonSearchbar, IonButtons, IonButton, IonBackButton, IonSpinner,
    IonSegment, IonSegmentButton, IonLabel, IonList, IonItem, IonIcon,
    IonThumbnail, IonImg, IonChip, IonRefresher, IonRefresherContent,
    IonInfiniteScroll, IonInfiniteScrollContent, IonSelect, IonSelectOption, IonPopover,
    CartButton, LoadingSkeleton,
    GetGiftToolbar, LogoImg,
  },
  setup() {
    const store = useStore();

    const { parentPath } = history.state; // route paramters (mainly ID)

    // 1. declare state variables (ref to make them reactive)
    const userDiscounts = computed(() => store.state.userDiscounts);
    const userLoggedIn = computed(() => store.state.loggedIn);
    const loading = computed(() => store.state.loadingProducts);
    const delayLoad = ref(true);
    const giftProducts = computed(() => store.getters.getValidGiftProducts);
    const gameProducts = computed(() => store.getters.getProductsByType('Game'));
    const products = computed(() => store.getters.getVisibleCoralProducts);
    const productCategories = computed(() => store.getters.getProductCategoriesByType('Coral'));

    // Filters
    const selectedCategoryId = ref("all");
    const selectedPriceRange = ref("all");
    const priceRanges = [
      { value: "< HK$500", min: 0, max: 500 },
      { value: "HK$500-1000", min: 500, max: 1000 },
      { value: "HK$1000-2000", min: 1000, max: 2000 },
      { value: "HK$2000-5000", min: 2000, max: 5000 },
      { value: "> HK$5000", min: 5000, max: Infinity },
    ];
    const numOfVisibleItems = ref(20);

    // Searchbar
    const searchKeyword = ref("");
    const productSearchBar = ref(null);
    const isSearching = ref(false);
    const showSearchBar = ref(false);

    const { t } = useI18n();
    const { getLocalizedStr, infiniteScrollLoadData, openModal, } = utils();

    const checkMatchKeyword = (obj: any, keyword: any) => {
      return `${obj.title?.toLowerCase()} ${obj.titleEn?.toLowerCase()} ${obj.coralGenusId?.toLowerCase()}`.includes(keyword);
    }
    const filteredProducts = () => {
      const catId = selectedCategoryId.value;
      let res = products.value.filter((obj: any) => ((catId == 'all' || catId == obj.categoryId) && checkMatchKeyword(obj, searchKeyword.value)));
      if (selectedPriceRange.value != 'all') {
        const range = priceRanges.find(r => r.value == selectedPriceRange.value);
        res = res.filter(p => (p.price >= range.min && p.price < range.max)).sort((a,b) => a.price - b.price);
        res = [
          ...res.filter(p => !p.auction && p.status == 'available'),
          ...res.filter(p => p.status != 'available' || (p.auction && p.auction.status != 'active')),
        ];
      }
      return res;
    }
    const loadData = (ev) => {
      infiniteScrollLoadData(ev, numOfVisibleItems, filteredProducts());
    }

    onIonViewDidEnter(() => {
      setTimeout(() => {
        delayLoad.value = false;
      }, 1000);
    })

    const isScrolling = ref(false);
    let scrollTimeout;

    const handleScroll = () => {
      isScrolling.value = true;
      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(() => {
        isScrolling.value = false;
      }, 250);
    };

    watch(selectedCategoryId, () => {
      numOfVisibleItems.value = 20;
      selectedPriceRange.value = "all";
    });
    watch(selectedPriceRange, () => {
      numOfVisibleItems.value = 20;
      //selectedCategoryId.value = "all";
    });


    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      search, searchOutline, notificationsOutline, bookmarksOutline, giftOutline, filter, checkmark, closeCircle,
      
      // state variables
      loading, delayLoad,
      parentPath, userLoggedIn,
      productCategories, selectedCategoryId,
      productSearchBar, searchKeyword, isSearching, showSearchBar,
      numOfVisibleItems,
      giftProducts, userDiscounts,
      isScrolling,
      selectedPriceRange, priceRanges,
      gameProducts,

      // methods
      t, getLocalizedStr,
      filteredProducts, loadData,
      fetchLatestProducts: (e: any = null) => {      
        ProductService.getAllProducts().then(res => {
          store.commit('receiveAllProducts', res);
          if (e) e.target.complete();
        })
      },
      handleScroll,

      // Only show valid price ranges for selected product category
      filteredPriceRange: () => {
        const catId = selectedCategoryId.value;
        const res = products.value.filter((obj: any) => ((catId == 'all' || catId == obj.categoryId) && checkMatchKeyword(obj, searchKeyword.value)));
        return priceRanges.filter(r => (res.some(p => p.price >= r.min && p.price < r.max)));
      },

      openUserGiftsModal: async () => (await openModal(UseCouponModal, { showUserDiscountsOnly: true })),

      setShowSearchbar: () => {
        showSearchBar.value = true;
        setTimeout(() => {
          productSearchBar.value.$el.setFocus();
        }, 100);
      }
    };
  },
};
