import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';

import { getPlatforms } from '@ionic/vue';

export default {
  // Gifts for users
  async getWelcomeGift() {
    const payload = {
      devicePlatforms: getPlatforms().join(" , "),
      userAgent: window.navigator.userAgent,
    }
    return (await firebase.app().functions('asia-east2').httpsCallable('gift-getWelcomeGift')(payload)).data;
  },

  // Get latest price for playing lucky wheel
  async getLatestLuckyDrawPrice(sessionId) {
    return (await firebase.app().functions('asia-east2').httpsCallable('gift-getLatestLuckyDrawPrice')({ sessionId })).data;
  },

  // Lucky Wheel Refresh Prizes
  async getLuckyWheelAvailablePrizes(sessionId, gameProductId, fullRefresh = true, skipPrizeIds = []) {
    const payload = {
      sessionId, gameProductId,
      devicePlatforms: getPlatforms().join(" , "),
      userAgent: window.navigator.userAgent,
      fullRefresh, skipPrizeIds,
    }
    return (await firebase.app().functions('asia-east2').httpsCallable('gift-getLuckyWheelAvailablePrizes')(payload)).data;
  },

  // Delete Game Session (on leave / end game)
  async deleteGameSession(sessionId) {
    return (await firebase.app().functions('asia-east2').httpsCallable('gift-deleteGameSession')({ sessionId })).data;
  },
}