
import { computed, onMounted, ref } from 'vue';

// icons
import { alertCircle, gift } from 'ionicons/icons';

// components
import { IonHeader, IonGrid, IonToolbar, IonButtons, IonButton, IonLabel,
        IonBackButton, IonIcon, IonTitle, IonModal, IonCard, IonCardHeader, IonCardTitle,
        loadingController, } from '@ionic/vue';
import LogoImg from "@/components/LogoImg.vue";

// composables
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { utils } from '@/composables/utils';

// Lottie
import lottie from 'lottie-web';

// services
import GiftService from "@/services/GiftService";

export default {
  props: [],
  components: { IonHeader, IonGrid, IonToolbar, IonButtons, IonButton, IonLabel,
                IonBackButton, IonIcon, IonTitle, IonModal, IonCard, IonCardHeader, IonCardTitle,
                LogoImg },
  setup() {
    const { t } = useI18n();
    const { getLocalizedStr, isMobileWebApp, isAndroid, isNativeApp, presentAlert, openLoginModal, addResizeUrlParams, } = utils();

    const store = useStore();
    const settings = computed(() => store.state.settings);
    const user = computed(() => store.state.user);
    const userLoggedIn = computed(() => store.state.loggedIn);
    const resultGift = ref({});

    const isGiftModalOpened = ref(false);
    const showGiftBoxAnimation = ref(false);
    let lottieAnim;

    // Auth guard
    const checkLoggedIn = () => {
      if (userLoggedIn.value) return true;
      openLoginModal();
      return false;
    }

    const getGift = async () => {
      if (checkLoggedIn()) {
        store.commit('setForceHideBottomTabs', true);
        const loading = await loadingController.create({ duration: 60000 });
        await loading.present();        
        const { errorMsg, gift } = await GiftService.getWelcomeGift();
        loading.dismiss();

        if (gift && gift.id) {
          showGiftBoxAnimation.value = true;
          resultGift.value = gift;

          setTimeout(() => {
            lottieAnim.goToAndPlay(0);
            lottieAnim.addEventListener('complete', async () => {
              // open gift modal
              isGiftModalOpened.value = true;
            });

            // to get latest user coupons
            store.dispatch('getUserData', {});
          }, 500);
        } else {
          presentAlert(errorMsg);
          store.commit('setForceHideBottomTabs', false);
        }
      }
    }

    onMounted(() => {
      // Lottie Init
      const container: any = document.querySelector('#lottie-animation');
      lottieAnim = lottie.loadAnimation({
        name: "Gift Box Unpack",
        container: container,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: require('@/assets/lottie/gift_box.json'),
      });
      lottieAnim.setSpeed(0.5);
      /*
      setTimeout(() => {
        if (userLoggedIn.value && settings.value.showGetGiftToolbar == 1 && user.value.gotWelcomeGift == null) {
          getGift();
        }
      }, 150);
      */
    })

    return {
      // icons
      alertCircle, gift,

      // variables
      settings, user, userLoggedIn,
      showGiftBoxAnimation,
      isGiftModalOpened,
      resultGift,

      // methods
      t, getLocalizedStr,
      isNativeApp, isMobileWebApp, isAndroid,
      addResizeUrlParams,

      getGift,

      onCompleteGetGift: async () => {
        showGiftBoxAnimation.value = false;
        isGiftModalOpened.value = false;
        store.commit('setForceHideBottomTabs', false);
      },
    }
  }
}
